import { 
    ArrowRight, 
    ArrowLeft,
   } from "react-bootstrap-icons";

function Quantity({ item, list, setList }) {
    const changeQuantity = (increase) => {
      let tempList = list.slice();
      tempList.forEach((listItem) => {
        if (listItem.id === item.id) {
          if (increase) {
            listItem.quantity = item.quantity + 1;
          } else if (!increase) {
            listItem.quantity = item.quantity - 1;
          }
        }
      });
      setList(tempList);
    };
  
    return (
      <div>
        <ArrowLeft
          onClick={() => changeQuantity(false)}
          className="mr-3 pointer"
        />
        {item.quantity}
        <ArrowRight
          onClick={() => changeQuantity(true)}
          className="ml-3 pointer"
        />
      </div>
    );
  }
  export default Quantity;