import {
    Container,
    Form,
    FormControl,
  } from "react-bootstrap";

function AddItem(props) {
    const handleSubmit = (e) => {
      e.preventDefault();
      props.onAdd(props.value);
    };
  
    return (
      <Container>
        <Form onSubmit={handleSubmit} className="d-flex">
          <FormControl
            className="mb-3"
            placeholder="New Item"
            value={props.value}
            onChange={(e) => props.onChange(e)}
          ></FormControl>
        </Form>
      </Container>
    );
  }
  export default AddItem;