import React, { useState } from "react";
import { Container, Row, Col, Accordion, Button, Card } from "react-bootstrap";
import useLocalStorage from "./useLocalStorage";
import "./App.css";
import AddItem from "./Components/AddItem";
import TodoItem from "./Components/TodoItem";

function App() {
  const [list, setList] = useLocalStorage("Shoppinglist", []);
  const [input, setInput] = useState("");

  return (
    <div className="App">
      <Container>
        <Row>
          <Col>
            <h1>Shoppinglist</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <AddItem
              onChange={(e) => setInput(e.target.value)}
              value={input}
              onAdd={(name) => {
                setList([
                  ...list,
                  {
                    id:
                      list.length !== 0
                        ? Math.max(...list.map((item) => item.id)) + 1
                        : 0,
                    name: name,
                    done: false,
                    date: new Date().toLocaleDateString(),
                    quantity: 1,
                  },
                ]);
                setInput("");
              }}
            />
          </Col>
        </Row>
        <Accordion defaultActiveKey="0" className="mb-3">
          <Card>
            <Card.Header>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="accHeader"
              >
                Offen
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <ul className="m-3">
                {list
                  .filter((item) => !item.done)
                  .map((item, idx) => (
                    <>
                      <TodoItem
                        item={item}
                        idx={idx}
                        list={list}
                        setList={setList}
                        done={item.done}
                      />
                    </>
                  ))}
              </ul>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion className="mb-5">
          <Card>
            <Card.Header>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="accHeader"
              >
                Erledigt
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <ul className="m-3">
                {list
                  .filter((item) => item.done)
                  .map((item, idx) => (
                    <TodoItem item={item} idx={idx} done={item.done} />
                  ))}
              </ul>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
    </div>
  );
}
export default App;
