import { Button } from "react-bootstrap";
import { InfoCircle, Check2Square, XSquare } from "react-bootstrap-icons";
import Quantity from "./Quantity";
import ReactTooltip from "react-tooltip";


function TodoItem({ item, idx, list, setList, done }) {
  const handleDelete = (id) => {
    setList(list.filter((item) => item.id !== id));
  };

  const handleChange = (id) => {
    for (let listItem of list) {
      if (listItem.id === id) {
        listItem.done = !listItem.done;
      }
    }
    setList([...list]);
  };

  return (
    <>
      {!done ? (
        <li key={idx} className="d-flex justify-content-between">
          <div>
            <span className="mr-1">{item.name}</span>
            <InfoCircle data-tip={"Erstellt am " + item.date}/>
            <ReactTooltip  place="right" type="info" effect="solid"/>
          </div>
          <div className="d-flex">
            <Quantity item={item} list={list} setList={setList} />
            <Check2Square
              onClick={() => handleChange(item.id)}
              className="ml-4"
              size={25}
            />
          </div>
        </li>
      ) : (
        <li key={idx} className="d-flex justify-content-between">
          <div>
            {item.name + "  "}
            <InfoCircle data-tip={"Erstellt am " + item.date}/>
            <ReactTooltip  place="right" type="info" effect="solid"/>
          </div>
          <div>
            {item.quantity + " Elemente"}
            <Button
              variant="danger"
              className="mr-1 ml-4"
              onClick={() => handleDelete(item.id)}
            >
              Löschen
            </Button>
            <XSquare onClick={(e) => handleChange(item.id)} size={25} />
          </div>
        </li>
      )}
      <hr />
    </>
  );
}
export default TodoItem;
